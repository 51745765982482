<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="col-12">
                        <div class=""><strong>Ref Number #: </strong> <span class="fw-bold fs-5">{{ item.id}}</span></div>
                    </div>
                </div>
                <slot name="actions"></slot>
            </div>
            <div class="pt-3">
                <div class="row g-2 align-items-center">
                    <div class="col-md-6">
                        <div class=""><strong>Weight: </strong> <span v-if="item.weight"> {{ item.weight }} kg </span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Height: </strong> <span v-if="item.height"> {{ item.height }} m </span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Pulse: </strong> <span v-if="item.pulse">{{ item.pulse}} bpm </span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Blood Pressure:</strong> <span v-if="item.blood_pressure"> {{ item.blood_pressure}} mmHg</span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>BMI: </strong> <span v-if="item.bmi"><span v-html="$filters.evaluateBMI(item.bmi)"></span> ( {{ item.bmi}} kg/m<sup>2</sup> ) </span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>No. of Drugs Prescribed #: </strong> {{ item.drugs_count || 'Nil'}}</div>
                    </div>
                    <div class="col-12">
                        <div class=""><strong>Ailments: </strong> {{ item.ailments.map(i => i.title).join(', ')}}</div>
                    </div>
                    <div class="col-12">
                        <div class=""><strong>Date created: </strong> {{ $filters.date(item.created_at, 1) }}</div>
                    </div>
                </div>
                <div v-if="item.doctor" class="mt-2">
                    <hr class="my-1">
                    <div>
                        <p class="mb-2"> Doctor's Details</p>
                        <div class="d-flex">
                            <img :src="absoluteUrl(item.doctor.image)" class="rounded-circle me-3 avatar" />
                            <div>
                                <h3 class="card-title mb-1">
                                    <span>
                                        {{item.doctor.first_name}} {{item.doctor.last_name}}
                                    </span>
                                </h3>
                                <p class="mb-1 small text-muted">{{item.doctor.mobile}} / {{ item.doctor.email }} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class=""><strong>Hospital: </strong> 
                    <span class="">
                        {{item.hospital.name}}
                        <span v-if="item.hospital.ward" class="mb-0">
                        {{item.hospital.ward.name}}
                        </span>
                        <span v-if="item.hospital.lga">,  
                        {{item.hospital.lga.name}} LGA
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props:{
            item:{
                type: Object,
                required: true
            }
        },
    }
    </script>
    
    <style>
    
    </style>