
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="row gy-2 justify-content-between">
        <div class="col-sm-12 d-flex justify-content-between">
          <div class="d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="exportRecord()">
                  <button class="dropdown-item" type="button">
                    Export Medical Record
                  </button>
                </li>
              </ul>
            </div>
            <div class="input-group">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            </div>
          </div>
          <button class="btn btn-primary" type="button" @click.prevent="popupModalShow = true">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Add Medical Record </span></button>
        </div>
        <div class="col-sm-12">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>

      <is-loading v-if="isLoading" />
      <div v-else>
        <div class="row">
          <div class="col-12" v-for="(item, index) in pageData.data" :key="index">
            <medical-history-card :item="item">
              <template v-slot:actions>
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li v-if="activeUser.id == item.doctor_id">
                      <router-link :to="`/medical-histories/${item.id}?add_drug=1`" class="dropdown-item"> Add Medicine</router-link>
                    </li>
                    <li>
                      <button @click.prevent="navigateTo(item.id)" class="dropdown-item" type="button">
                        View Record
                      </button>
                    </li>
                    <li>
                      <button @click.prevent="downloadReceipt(item.id)" class="dropdown-item" type="button">
                        Print Record
                      </button>
                    </li>
                  </ul>
                </div>
              </template>
            </medical-history-card>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal size="lg" v-model="popupModalShow" hide-footer
      title="Create medical history"
      body-class="loading-viewport" title-class="fs-6" @hide="initializeAll()">
      <medical-history-form 
        v-if="popupModalShow" :patientId="patientId"  
        @closeMe="initializeAll()"
      />
    </b-modal>
  </div>
</template>


<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue'
import MedicalHistoryForm from '@/components/forms/MedicalHistoryForm.vue';
import MedicalHistoryCard from '@/components/cards/MedicalHistoryCard.vue';
  
  export default {
    name: "patient-histories",
    components:{
      IsLoading,
      MedicalHistoryForm,
      MedicalHistoryCard,
    },
    props:{
      patientId:{
        type: Number,
        required: true
      }
    },
    data() {
      return {
        isLoading: true,
        popupModalShow: false,
        searchQuery:"",
        pageOptions: [10, 50, 100, 500, 1000],
      }
    },
    computed: {
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      pageData(){
        return this.$store.state.medicalHistory.pageData
      },
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchItems(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 10
        },
        set(val) {
          this.fetchItems(1, val)
        }
      },
      paginationInfo(){
        if(!this.pageData.total) return '0 - 0 of 0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
      },
    },
    methods: {
      navigateTo(id){
        this.$router.push({path:`/medical-histories/${id}`});
      },
      updateSearchQuery: _.debounce(function(string) {
        this.fetchItems()
      }, 2000),
      fetchItems(page, per_page = null){
        if(!this.patientId) return; 
        let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
                      patient_id: this.patientId }
        if(this.searchQuery){ payload.search = this.searchQuery }
        this.isLoading = true
        this.$store.dispatch("medicalHistory/fetchItems", payload)
        .then(response => this.isLoading = false)
      },
      addItem(item){
        this.pageData.data.unshift(item);
      },
      exportRecord() {
        const payload = {
          url: '/generate-record',
          data: {  patient_id: this.patientId, receipt: '4e237f46_c4a5_41b3_b3c3_7a7a85605af7' }
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.serverReceipt(payload)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.status != 200){
              this.alertError('Could not generate medical histories');
            }
        })
      },
      downloadReceipt(itemId) {
        const payload = {
          url: '/generate-record',
          data: {  model_id: btoa(itemId), receipt: 'e6bb4808_1b46_45bd_8a5f_aadd2066a80e' }
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.serverReceipt(payload)
            .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.status != 200){
                this.alertError('Could not generate receipt');
            }
        })
      }, 
      initAdd(){
        this.popupModalShow = true  
      },
      initializeAll(){
        this.popupModalShow = false;
      },
    },
    created() {
      this.fetchItems()
    }
  }
  
  </script>
  
  