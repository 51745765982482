<template>
  <form autocomplete="off" class="">
    <div class="row gy-3">
      <div class="col-12">
        <label class="form-label">
            Status <span class="text-danger">*</span>
        </label>
        <select class="form-select" v-model="data.status"
        name="status" placeholder="--select status--">
            <option disabled value="">--select status--</option>
            <option v-for="status in statusOptions" :key="status.id"
                :value="status.value">{{status.label}}</option>
        </select>
        <div v-for="(item, index) in v$.data.status.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>  
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" 
          @click.prevent="update()"
            type="button">Update</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
      return { v$: useVuelidate() };
  },
  props:{
      editItem:{
          type: Object,
          default: null
      },
      editMode:{
          type: Boolean,
          default: true
      }
  },
  data(){
    return{
      data: {
          status:"",
      },
      statusOptions:[
        { value: "on_queue", label: "On Queue" },
        { value: "in_consultation", label: "In consultation" },
        { value: "checked_out", label: "Checked out" },   
        { value: "cancelled", label: "Cancelled" },     
        { value: "admitted", label: "Admitted" },
        { value: "discharged", label: "Discharged" },   
        { value: "scheduled", label: "Scheduled" },     
        { value: "under_treatment", label: "Under treatment" },
      ],
    }
  },
  validations() {
    return {        
      data:{
        status: { required: required},            
      }
    }
  },
  methods:{
    update(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/hospital-patients/${this.editItem.id}/update`, this.data)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('updatedItem', response.data.data)
                    this.resetForm()
                }
            })
    
      })
    },
    resetForm(){
        this.v$.$reset()
        this.$emit("closeMe")
    }
  },
  mounted(){
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>