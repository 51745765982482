
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Patient Details</h5>
      <div class="row g-4">
        <div class="col-md-8 order-2 order-md-0">
          <div v-if="item.status == 'in_consultation'" class="bd-callout mt-0 mb-3 bd-callout-info">
            You are expected to update this patient's status after Consultation.
            <div class="d-grid d-sm-block pt-3">
              <button @click.prevent="popupModalShow = true" class="btn btn-primary" type="button">Checkout Patient</button>
            </div>
          </div>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="mb-3">
                  <div class="">
                    <strong>Status: </strong>
                    <span class="" v-html="statusBadge(item.status)"> </span>
                  </div>
                </div>
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="popupModalShow = true"
                       class="dropdown-item" type="button"> Update Status</button>
                    </li>
                    <li>
                      <button @click.prevent="addAttribute('history')" class="dropdown-item" type="button">
                        Add Medical Record
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row g-3 g-md-4">
                <div class="col-12">
                  <strong>Tag: </strong>
                  <span class="" >{{  item.tag }} </span>
                </div>
                <div class="col-12">
                  <strong>Health Complaint: </strong>
                  <div v-html="preText(item.health_complaint)" class="" ></div>
                </div>
                <div class="col-12">
                  <strong>Assigned on: </strong>
                  <span class="" >{{  $filters.date(item.created_at, 1) }} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-5">
            <h5 class="mb-3">Medical Histories</h5>
            <patient-histories  ref="patientHistories" :patientId="patient.id" /> 
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky" style="top: 90px;">
            <div class="card bg-white overflow-hidden">
              <div class="card-body">
                <div class="">
                  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img :src="absoluteUrl(patient.image)"
                      class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                  </div>
                  <h5 class="fs-16 mb-1">
                    {{patient.first_name}} {{patient.middle_name}} {{patient.last_name}}
                  </h5>
                  <p class="mb-0"> {{ patient.friendly_id }}</p>
                  <p class="mb-0"> Joined on {{$filters.date(patient.created_at)}}</p>
                </div>
                <hr>
                <div class="row g-3 g-md-4">
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Category</label>
                    <div class="text-uppercase">{{patient.patient_category}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Phone</label>
                    <div>{{patient.mobile}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Gender</label>
                    <div class="text-uppercase">{{patient.gender}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Date of Birth</label>
                    <div>{{$filters.date(patient.dob)}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">NIN</label>
                    <div>{{patient.nin}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Email</label>
                    <div>{{patient.email}}</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Age</label>
                    <div class="">{{patient.age}} years</div>
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label fw-bold">Occupation</label>
                    <div>{{patient.occupation}}</div>
                  </div>
                  <div class="col-12">
                    <label class="form-label fw-bold">Religion</label>
                    <div>{{patient.religion}}</div>
                  </div>
                  <div class="col-12">
                      <label class="form-label fw-bold">Stret Address</label>
                      <div>{{patient.address}}</div>
                  </div>
                  <div class="col-12 col-md-6">
                      <label class="form-label fw-bold">L.G.A</label>
                      <div v-if="patient.lga">{{ patient.lga.name}}</div>
                  </div>
                  <div class="col-6 col-md-6">
                      <label class="form-label fw-bold">State</label>
                      <div v-if="patient.state">{{patient.state.name}}</div>
                  </div>
                </div>
              </div>
              <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update Status" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <hospital-patient-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editItem="item" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import HospitalPatientForm from '@/components/forms/HospitalPatientForm.vue'
import PatientHistories from './PatientHistories.vue'

export default {
  components:{
    IsLoading,
    HospitalPatientForm,
    PatientHistories
  },
  data(){
    return{
      isLoading: true,
      popupModalShow: false,
      item: null
    }
  },
  computed:{
    patient(){
      return this.item.patient
    }
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/hospital-patients/${this.$route.params.itemId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
          this.promptStatusUpdate()
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    promptStatusUpdate(){
      if(this.item.status != 'on_queue') { return }
      this.confirmAction({
        title: "Status Update",
        text: "Should the patient's status be updated from <strong>Queue</strong> to <strong>Consultation</strong>?",
      }).then((result) => {
        if (result.value) { 
          this.$store.dispatch("changeLoaderValue", true)
          this.$http.post(`/hospital-patients/${this.item.id}/update`, { status: 'in_consultation' })
            .then((response) => {
              this.$store.dispatch("changeLoaderValue", false)
              if(response.data.success){
                Object.assign(this.item, response.data.data)
              }
            })
        }
      });
    },
    addAttribute(attribute){
      if(attribute == 'history'){
        this.$refs.patientHistories.initAdd()
      }
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}
</script>


<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>